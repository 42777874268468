import { gql } from "@apollo/client";

export const CREATE_HCP_DOCUMENT = gql`
  mutation CREATE_HCP_DOCUMENT(
    $subType: String
    $fileUrl: String
    $hcpId: ObjectId
    $name: String!
    $uploadedFor: ObjectId!
    $covidTest: DateTime
    $fileStorageRootFolder: String
    $fileStorageFileKey: String
  ) {
    createHcpDocument(
      fileUrl: $fileUrl
      hcpId: $hcpId
      name: $name
      uploadedFor: $uploadedFor
      covidTest: $covidTest
      fileStorageRootFolder: $fileStorageRootFolder
      fileStorageFileKey: $fileStorageFileKey
      subType: $subType
    ) {
      _id
      name
      covidTest
    }
  }
`;

export const GET_REQUIREMENTS_STATUS = gql`
  query GET_REQUIREMENTS_STATUS($hcpId: ObjectId!, $hcfIds: [ObjectId!]) {
    hcpRequirementStatus(hcpId: $hcpId, hcfIds: $hcfIds) {
      miscellaneous {
        _id
        name
        instructions
        description
      }
      requirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
        expiry
      }
      completed
      pending
      expired
      missing
      optionalRequirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
        expiry
      }
      validOptionalRequirements
      expiredOptionalRequirements
      missingOptionalRequirements
      rejected
      mostRecentCovidDoc {
        covidTest
      }
    }
  }
`;

export const GET_MISSING_REQUIREMENTS_FOR_DATE = gql`
  query GET_MISSING_REQUIREMENTS_FOR_DATE(
    $hcfId: ObjectId!
    $date: DateTime!
    $qualification: String!
  ) {
    hcpMissingRequirementsForDate(hcfId: $hcfId, date: $date, qualification: $qualification) {
      _id
      reqId
      name
      level
      hcfType
      requiredBy
      instructions
      description
      visibleToHCP
      expiry
    }
  }
`;

export const DELETE_HCP_DOCUMENT = gql`
  mutation DELETE_HCP_DOCUMENT($_id: ObjectId!) {
    deleteHcpDocument(_id: $_id) {
      _id
      name
      displayName
      expiry
      visible
      status
      fulfilledRequirement {
        _id
        name
      }
    }
  }
`;

export const GET_HCP_REQUIREMENTS = gql`
  query GET_HCP_REQUIREMENTS($hcpId: ObjectId!, $filter: RequirementsFilter) {
    hcpRequirementList(hcpId: $hcpId, filter: $filter) {
      expiry
      baseRequirement {
        expiryType
      }
    }
  }
`;

export const GET_PRESIGNED_UPLOAD_URL = gql`
  query GET_PRESIGNED_UPLOAD_URL($reqId: ObjectId!, $fileType: String!) {
    getPresignedUploadUrl(reqId: $reqId, fileType: $fileType) {
      preSignedUrl
      fileStorageRootFolder
      fileStorageFileKey
    }
  }
`;
