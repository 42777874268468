import { App as CapApp } from "@capacitor/app";
import { environmentConfig } from "@src/appV2/environment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { checkIfRouteIsValid } from "../utils/routeUtils";

export function useAppUrlListener() {
  const history = useHistory();

  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: { url: string }) => {
      if (!data.url.includes(environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN)) {
        return;
      }
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = data.url.split(environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN).pop();

      // Exception for auto-login link
      if (slug?.includes("/welcome/login/emailVerify")) {
        return;
      }

      if (slug && checkIfRouteIsValid(slug)) {
        history.push(slug);
      }
    });
  }, [history]);
}
