import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const fetchFacilityProfile = async (facilityId: string): Promise<any> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/${facilityId}`)
      .query({
        projection: "_id phone note tmz fullAddress.formatted location state geoLocation type",
      })
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { fetchFacilityProfile };
