import { Text } from "@clipboard-health/ui-react";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, IconButton } from "@mui/material";

export function NoFacilityShifts() {
  return (
    <Box>
      <IconButton edge="start" color="inherit">
        <DescriptionIcon />
        <Text variant="h3">Unfortunately, this shift is no longer available.</Text>
      </IconButton>
    </Box>
  );
}
