import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { environmentConfig } from "@src/appV2/environment";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import fetch from "cross-fetch";

const httpLink = createHttpLink({
  uri: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
  fetch,
});

const authLink = setContext(async () => {
  const token = await getFirebaseSingleton().currentUser?.getIdToken();
  return {
    headers: {
      Authorization: token || localStorage.getItem("authToken"),
    },
  };
});

const link = authLink.concat(httpLink);

const GraphQlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export { GraphQlClient };
