import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { PushMessage } from "./model";
import { getAuthHeader } from "../superagent";

const getMessageRecord = async (messageId: string, channel: string): Promise<PushMessage> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/notificationInteraction/getRecord`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query({ messageId, channel })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { getMessageRecord };
