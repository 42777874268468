import { Requirement } from "@src/appV2/Accounts/Documents/types";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";

import { GET_REQUIREMENTS_STATUS } from "./gql";
import { IFileUpload } from "./model";
import { SelectedFile } from "../shiftSignature/timecard/model";
import { uploadFileToFileStorage } from "../utils/fileUpload";
import { getDocumentFileName } from "../utils/getDocumentFileName";

const context = "[uploadSelectedDocument]";
interface UploadDocumentData {
  requirement: Requirement;
}

export const uploadSelectedDocument = async (
  selectedFile: SelectedFile,
  data: UploadDocumentData,
  client,
  hcpId: string | undefined
): Promise<IFileUpload> => {
  const { requirement } = data || {};
  let result: IFileUpload;
  if (!client || !selectedFile.fileBlob) {
    throw new Error(`${context} Tried upload without client or selectedFile`);
  }
  try {
    const { fileStorageFileKey, fileStorageRootFolder } = await uploadFileToFileStorage(
      selectedFile.fileBlob,
      selectedFile.type,
      requirement.reqId ?? "",
      client
    );
    const fileName = fileStorageFileKey?.split("/")[-1];
    const name = getDocumentFileName(requirement.name, fileName);
    result = {
      name,
      fileStorageRootFolder,
      fileStorageFileKey,
    };
    return result;
  } catch (err) {
    const errorMessage = `${context} Could not upload document file for hcp id: ${hcpId}, requirement: ${requirement}, id: ${requirement?._id}, reqId: ${requirement?.reqId}. Reason - ${err?.message}`;
    deprecatedDoNotUseLogError({
      message: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const updateCache =
  ({ covidTest, hcpId, selectedRequirement }) =>
  (cache): void => {
    const data = cache.readQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
    });
    const updatedData = { ...data.hcpRequirementStatus };
    const { requirement } = selectedRequirement;

    updatedData.pending = [...updatedData.pending, requirement.reqId];

    if (/covid/i.test(requirement.name)) {
      updatedData.mostRecentCovidDoc = { covidTest };
    }

    cache.writeQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
      data: { hcpRequirementStatus: updatedData },
    });
  };
