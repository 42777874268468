import { isDefined } from "@clipboard-health/util-ts";
import { tab as FriendsListTab } from "@src/app/components/workWithFriends/friendsList";
import { isAgentInSignupProcess } from "@src/app/routing/home/isAgentInSignupProcess";
import { ActionType as FriendStoreActionType } from "@src/app/store/workerFriends/model";
import { AgentPreferenceKey } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import type { Worker } from "@src/appV2/Worker/api/types";
import { SEARCH_MODE, USER_EVENTS } from "@src/constants";
import type { Agent } from "@src/lib/interface";
import { ActionType as SessionActionType, reloadAgentProfile } from "@store/session";
import { formatInTimeZone } from "date-fns-tz";
import { History, LocationDescriptorObject, Pathname, Search } from "history";
import moment from "moment-timezone";
import { Dispatch } from "react";

import { ActionType, DEEP_LINK_WWF_CANT_ADD_FRIEND_ALERT_PROPS } from "./constants";
import { isValidUrl, processBookWithMeDeepLink, processSuccessNonClinicalDeepLink } from "./utils";
import { updatePreference } from "../openShifts/api";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { WelcomeRouterPath } from "../routing/constant/welcomeRoute";
import { updateSharedShiftSlot } from "../store/shift";
import { checkIfRouteIsValid } from "../utils/routeUtils";

interface RequiredLocationProps extends LocationDescriptorObject {
  pathname: Pathname;
  search: Search;
}
/**
 *
 * FIXME - this needs a fundamental refactoring - we should never have more than 1 (or possibly 2)
 * function arguments. In addition, this has a lot of disparate logic that
 * is very difficult to reason about, and should be split into logical blocks.
 * Indeed - the only place this is used is inside hooks, which means this could be a hook.
 * In making this a hook, we can easily drop several arguments in favor of `useHistory`, etc.
 */
export const generateDynamicLinkListener = (
  worker: Agent | Worker | undefined,
  isAuthorized: boolean | undefined,
  setUrl: (url: string) => void,
  pushToLink: () => void,
  history: History,
  dispatch: Dispatch<any>,
  updateWorkerCache: (worker: Partial<Worker>) => void,
  setUnconsumedLink: (url: string) => void,
  setSegmentView?: (view: string) => void,
  setShiftBookingType?: (bookingType: string) => void
) => {
  const { userId } = worker ?? {};

  return async (data: { url?: string }) => {
    const pushHistory: (location: RequiredLocationProps) => void = history.push;
    if (!isDefined(data.url) || !isValidUrl(data.url)) {
      logError(USER_EVENTS.INVALID_DEEP_LINK, {
        metadata: { url: data.url },
        error: new Error("Invalid deeplink url"),
      });
      return;
    }

    try {
      const { pathname, searchParams } = new URL(data.url);
      setUrl(data.url);
      logEvent(USER_EVENTS.DEEP_LINK_OPENED, { url: data.url });
      if (pathname === "/__/auth/action") {
        if (data.url?.includes("linkVerify")) {
          history.push(WelcomeRouterPath.LOGIN_VERIFY_LINK, {
            url: data.url,
          });
        } else {
          history.push(WelcomeRouterPath.LOGIN_EMAIL_VERIFY, {
            url: data.url,
          });
        }
      } else if (pathname === `${TabRouterPath.SUCCESS_ONBOARDING}`) {
        localStorage.setItem("lastOnboarded", JSON.stringify(moment()));
        logEvent(USER_EVENTS.COMPLETED_STRIPE_FLOW, {
          hcpID: userId?.toString() ?? "",
        });
        history.replace(`${TabRouterPath.SUCCESS_ONBOARDING}`, {
          url: data.url,
        });
      } else if (data.url?.includes(TabRouterPath.DOCUMENT_VIEW)) {
        if (!userId || !isAuthorized) {
          setUnconsumedLink(data.url);
          return;
        }
        pushHistory({
          pathname,
          search: searchParams.toString(),
        });
      } else if (data.url?.includes(TabRouterPath.OPEN_SHIFTS)) {
        if (!userId || !isAuthorized) {
          setUnconsumedLink(data.url);
          return;
        }

        const actionType = searchParams.get("actionType");
        switch (actionType) {
          /**
           * Switch Case to route to non clinical open shifts.
           * If deep link has format https://<domain>/home/openShifts?actionType=nonClinical&qualification=<nonClinicalType>
           */
          case ActionType.NON_CLINICAL: {
            const updatedAgent = await processSuccessNonClinicalDeepLink(userId, searchParams);
            if (updatedAgent) {
              dispatch(reloadAgentProfile());
            }
            history.replace(pathname);
            return;
          }
          case ActionType.WORK_WITH_FRIENDS: {
            const sharedShiftStart = searchParams.get("sharedShiftStart") || "";
            const sharedShiftEnd = searchParams.get("sharedShiftEnd") || "";
            const sharedFacilityId = searchParams.get("sharedFacilityId") || "";
            const sharedShiftName = searchParams.get("sharedShiftName") || "";
            const date = searchParams.get("date") || "";

            if (
              !sharedFacilityId ||
              !sharedShiftStart ||
              !sharedShiftEnd ||
              !sharedShiftName ||
              !date
            ) {
              deprecatedDoNotUseLogError({
                message: `[processSharedShiftDeepLink] some params are missing. ${searchParams.toString()}`,
              });
              history.replace(pathname);
              return;
            }
            dispatch(
              updateSharedShiftSlot({
                sharedFacilityId,
                sharedShiftStart,
                sharedShiftEnd,
                sharedShiftName,
              })
            );
            history.push(`${TabRouterPath.OPEN_SHIFTS}/${date}`);
            return;
          }
          case ActionType.WORKER_AVAILABILITY: {
            if (!userId || !isAuthorized) {
              setUnconsumedLink(data.url);
              return;
            }
            const shiftStartDate = searchParams.get("shiftStartDate") ?? "";
            const shiftEndDate = searchParams.get("shiftEndDate") ?? "";
            const facilityId = searchParams.get("facilityId") ?? "";
            const qualification = searchParams.get("qualification") ?? "";
            const facilityName = searchParams.get("facilityName") ?? "";
            const searchMode = SEARCH_MODE.WORKER_AVAILABILITY;

            const formattedStartDate = formatInTimeZone(
              shiftStartDate,
              worker?.tmz ?? "UTC",
              "yyyy-MM-dd"
            );
            const formattedEndDate = formatInTimeZone(
              shiftEndDate,
              worker?.tmz ?? "UTC",
              "yyyy-MM-dd"
            );

            const queryParams = new URLSearchParams({
              searchMode,
              ...(formattedStartDate && { shiftStartDate: formattedStartDate }),
              ...(formattedEndDate && { shiftEndDate: formattedEndDate }),
              ...(facilityName && { facilityName }),
              ...(qualification && { qualification }),
            });

            if (!qualification) {
              history.push(TabRouterPath.OPEN_SHIFTS);
              return;
            }

            const openShiftsFacilityPathname = `/home/openShifts/${facilityId}/shifts`;
            if (qualification === worker?.preference?.qualification) {
              pushHistory({
                pathname: openShiftsFacilityPathname,
                search: queryParams.toString(),
              });
              return;
            }

            const updatedData = await updatePreference(
              AgentPreferenceKey.QUALIFICATION,
              qualification
            );

            logEvent(USER_EVENTS.QUALIFICATION_PREFERENCE_UPDATED, {
              qualification: qualification,
              old_value: worker?.preference?.qualification,
              source: searchMode,
            });

            updateWorkerCache(updatedData as Partial<Worker>);

            pushHistory({
              pathname: openShiftsFacilityPathname,
              search: queryParams.toString(),
            });
            return;
          }
          case ActionType.OPEN_MAP_VIEW:
            if (setSegmentView) {
              setSegmentView("map");
            }
            localStorage.setItem("savedFindShiftsSegment", "map");
            history.replace(TabRouterPath.OPEN_SHIFTS);
            return;

          case ActionType.OPEN_BLOCKS:
            if (setSegmentView) {
              setSegmentView("calendar");
            }
            if (setShiftBookingType) {
              setShiftBookingType("block-booking");
            }
            localStorage.setItem("savedFindShiftsSegment", "calendar");
            localStorage.setItem("savedShiftBookingType", "block-booking");
            history.replace(TabRouterPath.OPEN_SHIFTS);
            return;
          default:
            pushToLink();
            pushHistory({
              pathname,
              search: searchParams.toString(),
            });
            return;
        }
      } else if (data.url?.includes(TabRouterPath.FRIENDS_LIST)) {
        const actionType = searchParams.get("actionType");
        if (!userId || !isAuthorized) {
          setUnconsumedLink(data.url);
          if (actionType === ActionType.BOOK_WITH_ME) {
            const bookWithMeId = searchParams.get("bookWithMeId") || "";
            logEvent(USER_EVENTS.OPENED_FRIEND_LINK_LOGGED_OUT, {
              requesterId: userId?.toString() ?? "",
              bookWithMeId,
            });
          }
          return;
        } else if (worker && isAgentInSignupProcess(worker)) {
          dispatch({
            type: SessionActionType.SHOW_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT,
            data: DEEP_LINK_WWF_CANT_ADD_FRIEND_ALERT_PROPS,
          });
          return;
        }
        switch (actionType) {
          case ActionType.BOOK_WITH_ME: {
            const friendLinkActionData = await processBookWithMeDeepLink(searchParams);
            if (!friendLinkActionData) {
              return;
            }
            dispatch({
              type: FriendStoreActionType.SHOW_FRIEND_LINK_ALERT,
              data: friendLinkActionData,
            });
            history.replace(TabRouterPath.FRIENDS_LIST);
            return;
          }
          case ActionType.FRIEND_INVITES:
            history.replace(TabRouterPath.FRIENDS_LIST, {
              friendsListInitialTab: FriendsListTab.invites,
            });
            return;
          default:
            pushToLink();
            pushHistory({
              pathname,
              search: searchParams.toString(),
            });
            return;
        }
      } else if (checkIfRouteIsValid(pathname)) {
        pushToLink();
        pushHistory({
          pathname,
          search: searchParams.toString(),
        });
        return;
      } else {
        logError(USER_EVENTS.INVALID_DEEP_LINK, {
          metadata: { url: data.url, pathname },
          error: new Error("Invalid deeplink url"),
        });
      }
    } catch (error) {
      logError(USER_EVENTS.DEEP_LINK_ERROR, { metadata: { url: data.url }, error });
    }
  };
};
