import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { Redirect, generatePath, useParams } from "react-router-dom";

import { AppV2AccountRoutes } from "../routing/constant/appV2AccountRoutes";

/**
 * @deprecated
 * This page is only here to catch any remaining links to the old v1 route.
 * There are deep links that people send via text messages or notifications.
 * Those should be converted from this route to the v2 route.
 * (We should never see logs from this code.)
 * Monitor logs and then delete this file and routes after 2024-06-30
 */
export function DeprecatedHcfDocumentsPage() {
  const { hcfId, hcfName } = useParams<{
    hcfId: string;
    hcfName: string;
  }>();
  useLogEffect("Deprecated documents page visited", {
    hcfId,
    hcfName,
  });
  return (
    <Redirect
      to={generatePath(AppV2AccountRoutes.HCF_DOCUMENT_BY_ID_AND_NAME, {
        hcfId,
        hcfName,
      })}
    />
  );
}
