import { isDefined } from "@clipboard-health/util-ts";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Box, CircularProgress } from "@mui/material";
import { useAgentPreferencesMutation } from "@src/appV2/Agents/api/useAgentPreferences";
import { AgentPreferenceKey } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { PageWithHeader, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { HCF_PROFILE_SOURCE, SEARCH_MODE, USER_EVENTS } from "@src/constants";
import { Facility, Shift } from "@src/lib/interface/src";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  getSuccessfulQualificationChangeMessage,
  getUnsuccessfulQualificationChangeMessage,
} from "./constants";
import { NoFacilityShifts } from "./NoFacilityShifts";
import { FacilityDetails } from "../dayView/facilityDetails";
import { FacilityOpenShifts } from "../dayView/facilityDetails/facilityOpenShifts";
import { shouldShiftLeadToMyDetailsPage } from "../dayView/shiftList";
import { TabRouterPath } from "../routing/constant/tabRoute";

/*
 FIXME: @HaileyesusZ
 This component still uses some V1 patterns such as redux and ion-header.
 Complete migration to V2 is dependent on multiple qualification support (RFC https://docs.google.com/document/d/1nWoc26x2mlZs0iTgozWWkWV31_hhwEZ0Vc5mqBc58xc/edit#heading=h.qjv6cxpf7ket).
 The migration task can be tracked by the ticket https://clipboardhealth.atlassian.net/browse/CH-23052
*/
export const FacilityOpenShiftsList = () => {
  const location = useLocation();
  const { facilityId = "" } = useParams<{ facilityId: string }>();

  const [shiftDetails, setShiftDetails] = useState<Shift>();
  const [facilityDetails, setFacilityDetails] = useState<Facility>();
  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);

  const worker = useDefinedWorker();

  const queryClient = useQueryClient();
  const { showSuccessToast, showErrorToast } = useToast();

  const searchParams = new URLSearchParams(location.search);
  const qualification = searchParams.get("qualification");
  const title = searchParams.get("facilityName") ?? "Open Shifts";
  const searchMode = searchParams.get("searchMode") as SEARCH_MODE;

  const {
    mutate: mutateAgentPreferences,
    isLoading: isQualificationUpdateLoading,
    isError: isQualificationUpdateError,
  } = useAgentPreferencesMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries([GET_WORKER_PATH]);
      if (!qualification) {
        return;
      }
      showSuccessToast(getSuccessfulQualificationChangeMessage(qualification));
    },
    onError: () => {
      if (!qualification) {
        return;
      }
      showErrorToast(getUnsuccessfulQualificationChangeMessage(qualification));
    },
  });

  const isSearchModeAvailable = !isEmpty(searchMode);
  const isFacilityDetailsDialogOpen = !isEmpty(facilityDetails);

  const isQualificationPreferred = qualification === worker?.preference?.qualification;
  const isPageLoading = !isSearchModeAvailable || isQualificationUpdateLoading;

  const isFacilityShiftsListEnabled = !isPageLoading && isQualificationPreferred;

  const isNoFacilityShiftsPageEnabled = isEmpty(facilityId) || isQualificationUpdateError;

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard = false
  ) => {
    if (shouldShiftLeadToMyDetailsPage(shift, worker.userId)) {
      return;
    }
    if (isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilityDetails(shift.facility);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilityDetails(undefined);
    setShiftDetails(undefined);
    if (!isQualificationPreferred && isDefined(qualification)) {
      mutateAgentPreferences({
        key: AgentPreferenceKey.QUALIFICATION,
        value: qualification,
      });
    }
  };

  if (isNoFacilityShiftsPageEnabled) {
    return (
      <IonPage>
        <PageWithHeader
          appBarHeader={
            <AppBarHeader
              title={title}
              leftCta={<BackButtonLink defaultBackTo={TabRouterPath.OPEN_SHIFTS} />}
            />
          }
        >
          <NoFacilityShifts />
        </PageWithHeader>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={TabRouterPath.OPEN_SHIFTS} mode="ios" />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Box>
          {!isFacilityShiftsListEnabled && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {isFacilityShiftsListEnabled && (
            <FacilityOpenShifts
              facility={{ userId: facilityId }}
              onClose={() => {}}
              hcfProfileSource={searchMode}
              searchMode={searchMode}
              onFacilityDetailsClick={onClickOnItemFacilityDetails}
            />
          )}

          <IonModal isOpen={isFacilityDetailsDialogOpen}>
            {isFacilityDetailsDialogOpen && (
              <FacilityDetails
                onClose={closeFacilityDetails}
                facility={facilityDetails}
                shift={shiftDetails}
                displayExtraTimePayCard={displayExtraTimePayCard}
                hcfProfileSource={HCF_PROFILE_SOURCE.MAP}
                searchMode={SEARCH_MODE.HCF_PROFILE}
              />
            )}
          </IonModal>
        </Box>
      </IonContent>
    </IonPage>
  );
};
